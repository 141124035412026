:root {
  --primary-color: #0056b3;
  --secondary-color: #003366;
  --accent-color: #ffa500;
  --text-color: #333;
  --background-color: #ffffff;
  --card-background: #f8f8f8;
  --font-family: 'Arial', sans-serif;
  --line-height: 1.6;
}

body {
  font-family: var(--font-family);
  line-height: var(--line-height);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}

h1, h2, h3 {
  color: var(--primary-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.card {
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}