.App {
  text-align: center;
}

header {
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  margin-right: 10px;
}

nav {
  background-color: var(--secondary-color);
  padding: 0.5rem;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

nav ul li {
  margin: 0.5rem;
}

nav ul li a {
  color: var(--background-color);
}

.cover-image {
  background-size: cover;
  background-position: center;
  height: 600px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cover-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--background-color);
  text-align: center;
  padding: 20px;
}

.cover-overlay h2 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.cover-overlay p {
  font-size: 1.2em;
  max-width: 600px;
}

main {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

section {
  margin-bottom: 3rem;
}

.cta-button {
  display: inline-block;
  background-color: var(--accent-color);
  color: var(--text-color);
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--primary-color);
  color: var(--background-color);
}

footer {
  background-color: var(--secondary-color);
  color: var(--background-color);
  padding: 1rem;
}

@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
  }
  
  .card-container {
    flex-direction: column;
  }
}